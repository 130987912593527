@import "~@/sass/_variables";
















































































































.mvs-player__wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; }
.mvs-player__wrap_swiper-controls {
  position: absolute;
  top: -60px;
  z-index: 2;
  width: 85px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  button {
    cursor: pointer;
    &.--disabled {
      color: #979797;
      cursor: disabled; }
    &:hover {
      color: #979797; } } }
