$fontsUrl: '/fonts/';
$prefix: 'xp87ma';
@font-face {
  font-family: 'mvs-font-pack';
  src: url('#{$fontsUrl}mvs-font-pack.eot?#{$prefix}');
  src: url('#{$fontsUrl}mvs-font-pack.eot?#{$prefix}#iefix') format('embedded-opentype'),
  url('#{$fontsUrl}mvs-font-pack.ttf?#{$prefix}') format('truetype'),
  url('#{$fontsUrl}mvs-font-pack.woff?#{$prefix}') format('woff'),
  url('#{$fontsUrl}mvs-font-pack.svg?#{$prefix}#mvs-font-pack') format('svg');
  font-weight: normal;
  font-style: normal;
}
