.muvis-player {
  &__header {
    position: absolute;
    top: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 36px 36px 0 36px;
    @media only screen and (max-width: 375px) {
      padding: 16px 16px 0 16px; } }
  &__streams {
    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1; }
    &__info {
      margin-top: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1; }
    &-description {
      font-size: 20px;
      color: #fff;
      line-height: 24px;
      font-weight: 400;
      @media only screen and (max-width: 375px) {
        font-size: 13px;
        line-height: 15px; } }
    &-title {
      font-size: 16px;
      color: #fff;
      line-height: 20px;
      font-weight: 400;
      margin-top: 8px;
      @media only screen and (max-width: 375px) {
        font-size: 13px;
        line-height: 15px;
        margin-top: 4px; } } }
  &__content {
    position: absolute;
    // z-index: 1
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
 }    // background: rgba(230, 121, 121, .1)


  &__footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    max-height: 140px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // background: rgba(255, 255, 255, .3)
    padding: 0 36px 23px 36px;
    @media only screen and (max-width: 375px) {
      padding: 16px 16px 0 16px; } } }
