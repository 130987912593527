$vodColor: #00a2d3;

.muvis-player {
  &.--vod {
    .commentator_item {
      .muvis-player__play {
        background: $vodColor; } }
    .muvis-player__map-point {
      color: $vodColor;
      &.--active {
        color: lighten($vodColor, 25%); }
      &:hover {
        color: darken($vodColor, 15%); } }
    .muvis-player__paid-screen {
      .muvis-player__private-button {
        background: $vodColor;
        &:hover {
          background: darken($vodColor, 15%); } }
      .muvis-player__private-icon {
        color: $vodColor; } }
    .settings_quality_item.--active {
      color: $vodColor; }
    .muvis-player__calendar__timetable__item.--current {
      color: $vodColor; }
    .muvis-player__calendar-item.--active {
      .muvis-player__calendar-item__date {
        border-color: $vodColor; } }
    .muvis-player__button {
      i {
        color: $vodColor; }
      &.--active:before {
        color: $vodColor;
        // border: 3px ridge #fff
        border-radius: 100%; } }
    .muvis-player__progress {
      background: $vodColor;
      &:after {
        background: $vodColor; } }
    .muvis-player__extended-toggle {
      background: $vodColor; }
    .muvis-player__play {
      color: $vodColor;
      border-color: $vodColor !important; } } }
button[name="mvs-icon-vod"] {
  &.--active {
    border-color: $vodColor !important; } }
