@mixin abstract-icon() {
  font-family: 'mvs-font-pack', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  box-sizing: content-box;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@mixin icon($name, $suppress-abstract: true, $font: 'mvs-font-pack'){
  @if($suppress-abstract != true){
      @include abstract-icon();
  }
  font-family: $font !important;
  content: map-get($icons, $name);
}