$fontsUrl: "https://static.muvis.media/fonts/mvs-font-pack/";
$prefix: "xp87ma";
// @font-face
//   font-family: 'mvs-font-pack'
//   src: url("https://static.muvis.media/fonts/mvs-font-pack/mvs-font-pack.eot?uilos1")
//   src: url('https://static.muvis.media/fonts/mvs-font-pack/mvs-font-pack.eot?uilos1#iefix') format('embedded-opentype')
//   src: url("https://static.muvis.media/fonts/mvs-font-pack/mvs-font-pack.ttf?uilos1") format('truetype')
//   src: url("https://static.muvis.media/fonts/mvs-font-pack/mvs-font-pack.woff?uilos1") format('woff')
//   src: url("https://static.muvis.media/fonts/mvs-font-pack/mvs-font-pack.svg?uilos1#mvs-font-pack") format('svg')
//   font-weight: normal
//   font-style: normal
// @import "https://static.muvis.media/www/fonts/mvs-font-pack/style.css";
@import "icons/_styles";
@import "icons/_variables";
@import "./MvsLayout";
@import "./MvsHeader";
@import "./MvsPrivate";
@import "./liveTheme";
@import "./vodTheme";
@import "./landscape";
@import "./portrait";

$progressHeight: 4px;
$extendedActiveColor: #979797;

.mvs-player {
  max-width: 800px;
  max-height: 450px;
  min-height: 450px;
  min-width: 365px;
  width: 100%;
  height: auto;
  background-color: #000;
  // @media only screen and (max-width: 1365px)

  // @media only screen and (max-width: 990px)
  @media only screen and (min-width: 768px) {
    min-height: 202px;
    max-height: 202px;
    max-width: 100%;
    min-width: 100%; } }
.muvis-player {
  max-width: 800px;
  max-height: 450px;
  min-height: 450px;
  min-width: 750px;
  width: 100%;
  height: auto;
  overflow: hidden;
  // background-color: #000
  // @media only screen and (max-width: 1365px)

  // @media only screen and (max-width: 990px)
  @media only screen and (max-width: 375px) {
    min-height: 202px;
    max-height: 202px;
    max-width: 100%;
    min-width: 100%; }
  flex: 1;
  flex-grow: 1;
  border: 1px solid #fff;
  position: relative;
  display: flex;
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &-videotag {
    width: 100%;
    height: 100%; }
  &__settings {
    align-self: flex-end;
    flex-grow: 1;
    @media only screen and (max-width: 375px) {
      font-size: 30px !important;
      width: 30px !important;
      height: 30px !important;
      flex: 0 0 30px !important;
      line-height: 30px !important; } }
  &__play-controls {
    width: 100%;
    display: flex;
    flex-grow: 1;
    position: relative;
    bottom: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 1;
 }    // padding: 0 36px 23px 36px
  &__duration {
    position: relative;
    top: 2px;
    width: 100%;
    // display: flex
    // flex-direction: row
    // justify-content: flex-start
    // align-items: center
    flex-grow: 1;
    // height: $progressHeight
    // max-height: $progressHeight
    // background: #fff
    &-timer {
      position: absolute;
      top: 25px;
      left: 75px;
      font-size: 20px;
      font-weight: 300;
      color: #fff; } }
  &__progress {
    position: relative;
    display: block;
    width: 35%;
    height: $progressHeight;
    max-height: $progressHeight;
    &:after {
      content: '';
      top: -3px;
      position: absolute;
      z-index: 3;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      right: 0; } }
  &__features {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 74px;
    right: 0;
    > button {
      margin-left: 24px; }
    &-extended {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > button {
        margin-right: 24px; } } }
  &__extended-enabled {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .muvis-player__button {
      margin-right: 24px; } }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    button {
      margin-left: 24px; } }
  &__play {
    $play-button-size: 58px;
    min-width: $play-button-size;
    min-height: $play-button-size;
    max-width: $play-button-size;
    max-height: $play-button-size;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 3px solid #fff;
    border-radius: 100%;
    margin-right: 11px;
    position: relative;
    top: -13px;
    &[name="mvs-icon-player-play"] {
      &:before {
        margin-left: 5px;
        @media only screen and (max-width: 375px) {
          font-size: 23px;
          top: -13px; } } } }
  .muvis-player__button {
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &.--border {
      border: 3px solid #fff;
      border-radius: 100%;
      padding: 10px;
      background-color: rgba(0, 0, 0, .55); }
    span {
      padding: 0;
      margin: 0;
      font-size: 20px; }
    &:hover {
      border-color: #8d909b;
      color: #8d909b; }
    &.muvis-player__volume {
      color: #fff; }
    &.muvis-player__fullscreen {
      color: #fff; }
    &.muvis-player__settings {
      color: #fff; } }
  .muvis-player__button-wrap {
    $buttonSize: 44px;
    min-width: $buttonSize;
    min-height: $buttonSize;
    max-width: $buttonSize;
    max-height: $buttonSize;
    @media only screen and (max-width: 375px) {
      $buttonSize: 30px;
      min-width: $buttonSize;
      min-height: $buttonSize;
      max-width: $buttonSize;
      max-height: $buttonSize; }
    border: 3px solid #fff;
    display: flex;
    border-radius: 100%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    &:hover {
      border-color: #8d909b;
      color: #8d909b; }
    .muvis-player__button {
      flex-grow: 1;
      display: block;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 40px;
      i {
        @media only screen and (max-width: 375px) {
          font-size: 16px; } } } }
  .muvis-player__extended-toggle {
    .muvis-player__button {
      transform: scale(1, 1);
      &.--open {
        transform: scale(-1, -1); } } }

  .muvis-player__audioguide_toggle,
  .muvis-player__playlist_toggle {
    color: $extendedActiveColor; } }


// scrollbar

.muvis-player-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #979797;
  background-color: #979797; }

.muvis-player-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #979797; }
.muvis-player-scroll::-webkit-scrollbar-thumb {
  background-color: #5c5c5c; }

.mobile.portrait {
  .muvis-player {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      z-index: 20;
      background: {
        color: #000;
        image: url('/images/rotation.gif');
        size: 120px;
        repeat: no-repeat;
        position: center; } } } }
