/*icons*/
$icon-sizes: (10, 12, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 38, 40, 42, 44, 48, 62, 66, 75);
$icons: (
  mvs-icon-play-circle: "\e974",
  mvs-icon-question-window: "\e977",
  mvs-icon-hamburger: "\e97b",
  mvs-icon-list: "\e959",
  mvs-icon-info: "\e92b",
  mvs-icon-download: "\e932",
  mvs-icon-search: "\e933",
  mvs-icon-bookmark: "\e934",
  mvs-icon-settings: "\e935",
  mvs-icon-lock-private: "\e971",
  mvs-icon-user-unknown: "\e972",
  mvs-icon-user-group: "\e936",
  mvs-icon-user-group-unknown: "\e973",
  mvs-icon-question: "\e94b",
  mvs-icon-check: "\e93c",
  mvs-icon-x-strong: "\e942",
  mvs-icon-gear: "\e948",
  mvs-icon-checkbox-empty: "\e943",
  mvs-icon-checkbox-checked: "\e944",
  mvs-icon-figure: "\e949",
  mvs-icon-camera: "\e95f",
  mvs-icon-audioguide: "\e95a",
  mvs-icon-star: "\e938",
  mvs-icon-checkbox-large: "\e939",
  mvs-icon-folders: "\e93b",
  mvs-icon-bell: "\e93d",
  mvs-icon-chevron: "\e93f",
  mvs-icon-file: "\e940",
  mvs-icon-ticket: "\e941",
  mvs-icon-book: "\e94a",
  mvs-icon-link: "\e94f",
  mvs-icon-layout: "\e956",
  mvs-icon-map: "\e957",
  mvs-icon-subtitles: "\e958",
  mvs-icon-calendar: "\e960",
  mvs-icon-play-square: "\e967",
  mvs-icon-chevron-left-circle: "\e96e",
  mvs-icon-chevron-right-circle: "\e96f",
  mvs-icon-user-group-chat: "\e970",
  mvs-icon-time: "\e937",
  mvs-icon-circle: "\e950",
  mvs-icon-sector: "\e93a",
  mvs-icon-chat-empty: "\e978",
  mvs-icon-share: "\e95e",
  mvs-icon-cloud: "\e961",
  mvs-icon-map-point: "\e95b",
  mvs-icon-ru-text: "\e95c",
  mvs-icon-en-text: "\e95d",
  mvs-icon-sound: "\e968",
  mvs-icon-fullscreen: "\e969",
  mvs-icon-mute: "\e96a",
  mvs-icon-Play-Exclusion: "\e954",
  mvs-icon-player-pause: "\e952",
  mvs-icon-player-play: "\e953",
  mvs-icon-player-play-circle: "\e951",
  mvs-icon-info-simple-circle: "\e94d",
  mvs-icon-info-sector: "\e93e",
  mvs-icon-sound-circle: "\e94e",
  mvs-icon-user-group-unknown-circle: "\e945",
  mvs-icon-user-unknown-circle: "\e946",
  mvs-icon-book-unknown-circle: "\e947",
  mvs-icon-question-window-circle: "\e94c",
  mvs-icon-time-circle: "\e955",
  mvs-icon-logo: "\e907",
  mvs-icon-avatar: "\e92d",
  mvs-icon-upload: "\e930",
  mvs-icon-turn: "\e92f",
  mvs-icon-x: "\e926",
  mvs-icon-email: "\e908",
  mvs-icon-ru: "\e900",
  mvs-icon-en: "\e90f",
  mvs-icon-instagram: "\e904",
  mvs-icon-facebook: "\e905",
  mvs-icon-lock-closed: "\e901",
  mvs-icon-calendar-event: "\e920",
  mvs-icon-multicam: "\e912",
  mvs-icon-statistics: "\e917",
  mvs-icon-integration: "\e916",
  mvs-icon-playlist: "\e915",
  mvs-icon-calendar-notify: "\e91a",
  mvs-icon-scenario: "\e914",
  mvs-icon-player-paid: "\e918",
  mvs-icon-player-options: "\e919",
  mvs-icon-user-statistics: "\e92c",
  mvs-icon-ticket-circle: "\e92e",
  mvs-icon-question-circle: "\e931",
  mvs-icon-upload-circle: "\e91e",
  mvs-icon-scenario-circle: "\e929",
  mvs-icon-link-circle: "\e925",
  mvs-icon-bell-jingle: "\e927",
  mvs-icon-roollup: "\e91f",
  mvs-icon-mute-circle: "\e921",
  mvs-icon-fullscreen-circle: "\e922",
  mvs-icon-gear-circle: "\e923",
  mvs-icon-map-circle: "\e924",
  mvs-icon-subtitles-circle: "\e91d",
  mvs-icon-audioguide-circle: "\e91c",
  mvs-icon-playlist-circle: "\e911",
  mvs-icon-cam-circle: "\e91b",
  mvs-icon-paid-circle: "\e902",
  mvs-icon-lock-private-circle: "\e928",
  mvs-icon-calendar-notify-circle: "\e903",
  mvs-icon-calendar-circle: "\e906",
  mvs-icon-chevron-right: "\e90a",
  mvs-icon-multicam-circle: "\e90e",
  mvs-icon-layout-circle: "\e910",
  mvs-icon-handshake: "\e90c",
  mvs-icon-like: "\e90d",
  mvs-icon-play: "\e913",
  mvs-icon-bell-jingle-chat: "\e92a",
  mvs-icon-telegram: "\e909",
  mvs-icon-chat: "\e90b",
);