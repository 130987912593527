.muvis-player__private {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  &-instruction {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
    z-index: 5;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
  &-screen {
    width: 100%; }
  &-notification__wrapper {
    margin-top: 24px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    p {
      font-size: 22px;
      line-height: 27px;
      font-weight: 500;
      margin-left: 8px; } }
  &-wrapper {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 60%;
    max-width: 60%;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 36px; }

  &-form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 34px;
    align-self: flex-start;
    &__wrapper {
      display: flex;
      width: 70%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 22px; }
    &__input {
      flex-grow: 1;
      height: 40px;
      font-size: 22px;
      line-height: 27px;
      border-bottom: 2px solid #fff;
      margin-right: 16px; }
    &__text {
      font-size: 22px;
      line-height: 27px;
      font-weight: 400; } }
  &-content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    max-height: 275px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 24px;
    padding-left: 60px;
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: 350px;
      h3 {
        font-size: 21px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 6px; }
      p {
        font-size: 22px;
        line-height: 27px; } } }
  &-button {
    margin-top: 28px;
    font-size: 22px;
    line-height: 28px;
    font-weight: normal;
    padding: 11px 84px;
    color: #fff;
    border-radius: 10px;
    border: 2px solid #fff;
    cursor: pointer;
    align-self: flex-start;
    &:hover {
      color: #fff; } }
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    span {
      font-size: 22px;
      line-height: 27px;
      font-weight: 600; } } }
