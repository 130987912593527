@import "~@/sass/_variables";
@import "normalize";
@import "icons/_styles";
@import "icons/_variables";
@import "fonts";
@import "blocks";
@import "muvis-sdk/src/sass/mvsPlayer";
@import "landscape";
@import "portrait";

* {
  outline: none !important; }


body {
  display: flex;
  min-height: 100vh;
  font-family: $roboto;
  color: #fff;
  min-width: 320px;
  overflow-x: hidden;
  background: {
    color: #212121;
    // image: url('/images/home-mobile.png')
    // image: url('/images/home.png')
    // image: url('/images/video.png')
    // size: 360px
    // size: 1920px
    repeat: no-repeat;
    position: bottom center; } }


#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  // opacity: .6
  position: relative; }

.mvs {
  &-header {
    flex: 0 0 auto;
    position: fixed;
    top: 40px;
    z-index: 11;
    width: 230px;
    // left: 305px
    margin-right: 15px;
    &.--active {
      background: #212121; }
    @media only screen and (max-width: 998px) {
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid $light;
      margin-bottom: 20px;
      background: #212121;
      padding: 0 20px; }
    &__logo {
      display: inline-block;
      margin-bottom: 60px;
      @media only screen and (max-width: 998px) {
        width: 100px;
        margin-bottom: 0; }
      img {
        width: 100%; } }
    &__inner {
      @media only screen and (max-width: 998px) {
        display: none;
        position: fixed;
        top: 60px;
        left: 0;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100% - 60px);
        padding: 15px;
        background: rgba(0, 0, 0, 0.9);
        z-index: 11;
        &.--active {
          display: flex; } } }
    &__hamburger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 24px;
      height: 24px;
      padding: 4px 2px;
      cursor: pointer;
      user-select: none;
      @media only screen and (min-width: 998px) {
        display: none; }
      &.--active {
        .mvs-header__hamburger-item {
          background: $light;
          &:nth-child(1) {
            top: 7px;
            transform: rotate(45deg); }
          &:nth-child(2) {
            opacity: 0; }
          &:nth-child(3) {
            top: -7px;
            transform: rotate(-45deg); } } }
      &-item {
        position: relative;
        width: 100%;
        height: 2px;
        background: #fff;
        transition: .2s; } } }
  &-nav {
    width: 100%;
    padding: 5px;
    border: 3px solid $light;
    margin-bottom: 12px;
    background: #212121;
    @media only screen and (max-width: 767px) {
      width: 100%;
      background: #212121; }
    &__title {
      position: relative;
      padding: 0 10px;
      height: 40px;
      line-height: 48px;
      text-transform: uppercase;
      cursor: pointer;
      user-select: none;
      font-size: 24px;
      font-weight: 700;
      transition: .2s;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: 0;
        height: 100%;
        background: $light;
        transition: .5s; }
      &:hover {
        color: #ccc;
        &:after {
          left: 0;
          top: 0;
          width: 100%; } } }
    &__link {
      position: relative;
      display: block;
      height: 40px;
      line-height: 45px;
      padding: 0 20px;
      font-weight: 300;
      border: 1px solid $light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: .2s;
      @media only screen and (max-width: 767px) {
        padding: 0 5px;
        text-align: center; }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: 0;
        height: 100%;
        background: $light;
        transition: .5s; }
      &.active {
        color: #ccc;
        background: $light; }
      &:hover {
        color: #ccc;
        &:after {
          left: 0;
          top: 0;
          width: 100%; } } } }
  &-title {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    z-index: 2;
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px; }
    &.--reverse {
      justify-content: flex-end;
      .mvs-title__ico {
        order: -1;
        margin-left: 0;
        margin-right: 16px; }
      .mvs-title__line {
        width: calc(100% - 100px);
        @media only screen and (max-width: 767px) {
          width: calc(100% - 40px); } }
      .mvs-title__text {
        left: 135px;
        right: auto;
        @media only screen and (max-width: 767px) {
          left: 30px; } } }
    &__line {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 74px);
      height: 4px;
      background: #fff;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 58px);
        height: 2px; } }
    &__text {
      position: absolute;
      right: 106px;
      bottom: 0;
      display: flex;
      align-items: center;
      transform: translateY(50%);
      padding: 0 18px;
      font-size: 21px;
      font-weight: normal;
      text-transform: uppercase;
      background: #212121;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        right: 30px; }
      &-inner {
        position: relative;
        font-weight: 300;
        margin-left: 20px;
        @media only screen and (max-width: 767px) {
          display: none; }
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -2px;
          left: -11px;
          width: 2px;
          height: 28px;
          background: #fff; } } }
    &__ico {
      $titleIconSize: 74px;
      display: block;
      width: $titleIconSize;
      height: $titleIconSize;
      font-size: $titleIconSize;
      background: none;
      margin-left: 16px;
      @media only screen and (max-width: 767px) {
        $titleIconSize: 40px;
        width: $titleIconSize;
        height: $titleIconSize;
        font-size: $titleIconSize; }
      &.--rotate {
        transform: rotate(90deg); }
      img {
        max-width: 100%;
        max-height: 100%; } } }

  &-content {
    margin: 0 72px 30px 123px;
    max-width: 530px;
    &:last-child {
      margin-bottom: 0; }
    @media only screen and (max-width: 1279px) {
      width: 100%;
      max-width: 800px;
      padding: 0;
      margin: 0 auto 20px; }
    &:last-child {
      margin-bottom: 0; }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.22;
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 1.4; }
      &:last-child {
        margin-bottom: 0; } } }

  &-slider {
    margin-bottom: 50px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 15px; }
    &__wrap {
      position: relative;
      margin-left: 40px;
      padding-top: 35px;
      @media only screen and (max-width: 767px) {
        padding-left: 0; }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -100px;
        left: -300px;
        width: calc(100% + 600px);
        height: calc(100% + 200px);
        background: url('/images/bg2.png') no-repeat center top / contain;
        @media only screen and (max-width: 767px) {
          top: -30px;
          left: -100px;
          width: calc(100% + 200px);
          height: calc(100% + 100px); } } }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 80px;
      right: 27px;
      width: 90px;
      height: 40px;
      z-index: 5; }

    &__prev,
    &__next {
      $buttonSize: 32px;
      width: $buttonSize;
      height: $buttonSize;
      font-size: $buttonSize;
      color: #fff;
      cursor: pointer;
      user-select: none;
      transition: .2s;
      @media only screen and (max-width: 767px) {
        display: none !important; }
      &.--disabled {
        color: $light; } }
    &__prev {
      transform: rotate(180deg); }
    &__slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      min-height: 354px;
      padding: 0 28px 62px 50px;
      text-align: right;
      border: 2px solid #fff;
      @media only screen and (max-width: 767px) {
        align-items: flex-end;
        justify-content: center;
        min-height: 165px;
        padding: 15px; } }
    &__title {
      font-size: 35px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 15px; } }
    &__text {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.2;
      color: #b2b2b2;
      min-height: 52px;
      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 1.3; } }
    &__pagination {
      position: relative !important;
      padding-top: 20px;
      @media only screen and (min-width: 768px) {
        display: none !important; }
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border: solid 2px #525258;
        background: none;
        opacity: 1;
        &-active {
          background: rgba(255, 255, 255, 0.8);
          border: solid 2px rgba(255, 255, 255, 0.8); } } } }

  &-video {
    &__content {
      width: 100%;
      max-width: 800px;
      padding: 0; }
    &__item {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0; } }
    &__title {
      position: relative;
      font-size: 32px;
      text-transform: lowercase;
      margin-right: 32px;
      margin-bottom: 35px;
      @media only screen and (max-width: 767px) {
        font-size: 22px; }
      &:last-child {
        margin-right: 0; }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        right: -17px;
        width: 4px;
        height: 32px;
        background: #fff;
        @media only screen and (max-width: 767px) {
          height: 20px; } }
      &-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
 } }        // margin-bottom: 15px
    &__subtitle {
      font-size: 32px;
      font-weight: 300;
      text-transform: lowercase;
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        font-size: 20px; } } }

  &-tab {
    &.--simple {
      .mvs-tab__link {
        width: 70px;
        height: 4px;
        padding: 0;
        background: rgba(255, 255, 255, 0.4);
        margin-right: 15px;
        &.--active {
          background: #fff; } } }
    &.--text {
      .mvs-tab__link {
        width: 70px;
        padding-bottom: 10px;
        text-align: center;
        border-bottom: 4px solid #fff;
        margin-right: 15px;
        &.--active {
          border-bottom: 4px solid #00a2d3; } } }
    &__header {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: 20px; }
    &__link {
      padding-bottom: 20px;
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      width: 44px;
      height: 44px;
      &.--active {
        border-bottom: 4px solid #fff; }
      &:last-child {
        margin-right: 0; } }
    &__title {
      font-size: 26px;
      line-height: 1.2;
      font-weight: 500;
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        font-size: 20px; } }
    &__description {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 25px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 1.4; }
      &:last-child {
        margin-bottom: 0; } }
    &__functions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 25px;
      margin-top: 25px;
      &:last-child {
        margin-bottom: 0; }
      &-item {
        margin-right: 15px;
        &:last-child {
          margin-right: 0; } } } }

  &-player {
    position: relative;
    border: 2px solid #fff;
    z-index: 2;
    display: flex;
    video {
      position: relative;
      top: 0;
      width: 100%;
      height: 100%; }
    &:before {
      content: '';
      display: block;
      padding-top: 52%; }
    &__wrap {
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -10%;
        right: -15%;
        width: 120%;
        height: 120%;
        z-index: -1; } } } }




