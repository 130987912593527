.mvs-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 100%;
    height: 100%;
    flex-grow: 1;
    // opacity: .4
    .mvs-body__left-side {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      max-width: 30%; }
    .mvs-body__right-side {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      flex-grow: 1;
      padding-left: 10px; } }
  .mvs-wrap {
    width: 100%;
    max-width: 880px;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 998px) {
      max-width: 700px; }
    @media only screen and (max-width: 767px) {
      padding: 0 22px;
      max-width: 100%;
      overflow: hidden; } }
  &__inner {
    width: 100%;
    // max-width: 1095px
    @media only screen and (max-width: 998px) {
      max-width: 100%; } } }


.mvs-info-block {
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -10%;
    right: -15%;
    width: 120%;
    height: 120%;
    z-index: -1;
    background: {
      position: bottom center;
      repeat: no-repeat;
      size: contain; } } }

.mvs-page {
  &__content {
    padding: 0 120px 0 190px;
    // background: rgba(255, 255, 255, .3)
    @media only screen and (max-width: 998px) {
      width: 100%;
      max-width: 800px;
      padding: 0;
      margin: 0; }
    &-item {
      margin-bottom: 100px;
      &:last-child {
        margin-bottom: 0; }
      .mvs-tab {
        padding-left: 80px; } } } }

.features-wrapper {
  position: relative;
  margin-top: 65px;
  margin-bottom: 75px;
  .mvs-tab__description {
    justify-content: flex-start; }
  &:before {
    content: "";
    display: block;
    flex-grow: 1;
    width: 120vw;
    height: 100%;
    left: -34vw;
    top: -34px;
    position: absolute;
    z-index: -1;
    background: #272727;
    border: solid 2px #2e2e2e;
    padding: 34px 0;
    @media only screen and (max-width: 767px) {
      left: -20vw; } } }


.mvs-video__title-wrap {
  padding-left: 80px;
  margin-bottom: 0;
  .mvs-video__title {
    font-size: 22px;
    line-height: 27px; } }
.mvs-tab__description {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 140px; }
