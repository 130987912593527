@-ms-viewport {
  width: device-width
}

@-o-viewport {
  width: device-width
}

@viewport {
  width: device-width
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
  outline: none;
}

[hidden] {
  display: none
}

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

button,
input,
select,
textarea,
a {
  font-family: inherit;
  color: inherit;
  font-weight: inherit;
}

a{
  text-decoration: none;
}

input,
textarea,
button {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: none;
}

button {
  user-select: none;
}

img {
  display: block;
  -ms-interpolation-mode: bicubic
}

input {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: start;
  -webkit-writing-mode: horizontal-tb;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

textarea {
  resize: none;
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0
}
