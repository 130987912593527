.portrait {
  &.mobile {
    $paddingLeft: 15px;
    $mainIconSize: 40px;
    .mvs-player-control {
      &.isPlaying {
        .muvis-player__play {
          display: none; } } }
    .mvs-body__left-side {
      width: 0;
      .mvs-footer__logo-wrapper {
        display: none; } }
    .mvs-body__right-side {
      padding-left: 0; }
    .mvs-player__wrap_swiper-controls {
      margin-right: 10px;
      button.muvis-player__button {
        &.--disabled {
          color: #979797 !important;
          &:hover {
            color: #979797 !important; } }
        &:hover {
          color: #fff; } } }
    .mvs-title__ico {
      width: $mainIconSize;
      height: $mainIconSize;
      font-size: $mainIconSize;
      flex: 0 0 $mainIconSize;
      line-height: $mainIconSize; }
    .mvs-header {
      position: absolute;
      z-index: 10;
      top: 0;
      width: 100%;
      height: 60px;
      background: #212121;
      padding: 0 $paddingLeft; }
    .mvs-body {
      margin-left: 0;
      &.video {
        margin-top: 70px; }
      .mvs-body__inner {
        max-width: 100%;
        >.mvs-title {
          // flex-grow: 1
          // flex-direction: row
          // justify-content: space-between
          // align-items: center
          height: $mainIconSize*1.5;
          margin-bottom: 0;
          margin: 0 $paddingLeft; } }
      .mvs-wrap {
        max-width: 100%;
        padding: 0;
 }        // padding: 0 $paddingLeft
      .mvs-page__content {
        max-width: 100%; }
      .features-wrapper {
        &:before {
          left: -20vw; } }
      .mvs-page__content-item {
        // display: flex
        // flex-direction: column
        // align-items: flex-start
        // justify-content: flex-start
        // flex-grow: 0
        .mvs-video__title-wrap {
          padding: 0 $paddingLeft;
          .mvs-video__title {
            // flex-grow: 1
            height: 50px; } }
        .swiper-wrapper {
          .mvs-tab__description {
            padding: 0 !important; } }
        .mvs-tab__description {
          padding: 0 $paddingLeft !important; }
        .mvs-tab {
          padding: 0 $paddingLeft; }
        .mvs-player__wrap {
          padding: 0 !important; } }
      .mvs-content {
        max-width: 100%;
        padding: 0 $paddingLeft; }
      &.projects {
        hr {
          margin: 64px $paddingLeft; }
        .mvs-title {
          // flex-direction: row
          // justify-content: flex-start
          // align-items: center
          // width: 100%
          // text-align: left
          padding: 0 $paddingLeft; } } }
    .mvs-slider__wrap {
      margin: 0 $paddingLeft; }
    .mvs-form__wrap {
      padding: 0 $paddingLeft;
      .mvs-form {
        max-width: 100%; } }
    #features .mvs-features__slider {
      height: 130px; }
    #quizFeatures .mvs-quizFeatures__slider {
      height: 130px; }
    #quizForms .mvs-player__wrap_swiper-controls {
      display: none; }
    .mvs-player__wrap.--quizforms {
      left: unset;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .mvs-body.projects {
      .mvs-wrap {
        .mvs-title {
          height: unset;
          line-height: 1.2;
          margin: 0;
          margin-bottom: 15px; }
        ul {
          margin-left: 20px; } } }
    .mvs-body.privacy {
      .mvs-wrap {
        .mvs-title {
          padding: 0 $paddingLeft;
          height: unset;
          line-height: 1.25;
          margin: 0;
          margin-bottom: 15px; }
        h3 {
          line-height: 1.25;
          margin-top: 15px; }
        ul {
          margin-left: 20px; } } }
    .mvs-footer {
      padding-top: 100px;
      height: 390px;
      &__item {
        &.--logo {
          display: flex;
          width: 40%;
          height: 70px;
          align-items: center;
          .mvs-footer__logo img {
            max-width: 100%; } }
        &.--links {
          width: 50%;
          flex-grow: 1;
          // flex-direction: column
          justify-content: space-between;
          align-items: center;
          .mvs-footer__link {
            margin: unset;
            flex-grow: 1;
            height: 70px;
            justify-content: flex-end;
            &:last-child {
 } } }              // justify-content: flex-start
        &.--social {
          max-width: 100%; } }
      &__top {
        padding: 0 15px;
        margin-top: 35px;
        justify-content: center; }
      &__bottom {
        padding: 0 10px;
        width: 100%;
        .mvs-footer__personal-link {
          font-size: 16px; } } } }

  &.desktop,
  &.tablet {
    .mvs-body__wrapper {
      .mvs-body__left-side {
        max-width: 25%;
        padding-right: 30px; } }
    .mvs-footer {
      max-width: 880px;
      width: 100%; } } }
