.landscape {
  &.mobile {
    $paddingLeft: 30px;
    $mainIconSize: 40px;
    .mvs-player-control {
      &.isPlaying {
        .muvis-player__play {
          display: none; } } }
    .mvs-title__ico {
      width: $mainIconSize;
      height: $mainIconSize;
      font-size: $mainIconSize;
      flex: 0 0 $mainIconSize;
      line-height: $mainIconSize; }
    .mvs-title {
      margin: 0 10px; }
    .mvs-form {
      max-width: 95%; }
    .mvs-slider__wrap {
      padding-left: 0;
      margin-left: 10px; }
    .mvs-slider__slide {
      min-height: 90vh;
      justify-content: flex-end; }
    .mvs-slider__title {
      font-size: 24px; }
    .mvs-slider__text {
      font-size: 18px;
      margin-bottom: 50px; }
    .mvs-header {
      position: absolute;
      z-index: 10;
      top: 0;
      background: #212121;
      padding: 0 $paddingLeft; }
    .mvs-body {
      margin-left: 0;
      &.video {
        margin-top: 70px; }
      .mvs-body__inner {
        max-width: 100%; }
      .mvs-wrap {
        max-width: 100%;
        padding: 0;
 }        // padding: 0 $paddingLeft
      .mvs-page__content {
        max-width: 100%; }
      .features-wrapper {
        &:before {
          left: -20vw; } }
      .mvs-page__content-item {
        .mvs-video__title-wrap {
          padding: 0 $paddingLeft; }
        .swiper-wrapper {
          .mvs-tab__description {
            padding: 0 !important; } }
        .mvs-tab__description {
          padding: 0 $paddingLeft !important; }
        .mvs-tab {
          padding: 0 $paddingLeft; }
        .mvs-player__wrap {
          padding: 0 !important; } }
      .mvs-content {
        max-width: 100%;
        padding: 0 $paddingLeft; }
      &.projects {
        hr {
          margin: 64px $paddingLeft; }
        .mvs-title {
          // flex-direction: row
          // justify-content: flex-start
          // align-items: center
          // width: 100%
          // text-align: left
          padding: 0 $paddingLeft; } } }
    .mvs-body.projects {
      .mvs-wrap {
        .mvs-title {
          height: unset;
          line-height: 1.2;
          margin: 0;
          margin-bottom: 15px; }
        ul {
          margin-left: 20px; } } }
    .mvs-body.privacy {
      .mvs-wrap {
        .mvs-title {
          padding: 0 $paddingLeft;
          height: unset;
          line-height: 1.25;
          margin: 0;
          margin-bottom: 15px; }
        h3 {
          line-height: 1.25;
          margin-top: 15px; }
        ul {
          margin-left: 20px; } } }
    .mvs-footer {
      padding-top: 100px;
      // height: 390px
      &__item {
        &.--logo {
          display: flex;
          width: 40%;
          height: 80px;
          .mvs-footer__logo img {
            max-width: 100%; } }
        &.--links {
          width: 50%;
          flex-grow: 1;
          // flex-direction: column
          justify-content: space-between;
          .mvs-footer__link {
            margin: unset;
            flex-grow: 1;
            height: 80px;
            justify-content: flex-end; } }
        &.--social {
          max-width: 100%;
          margin-top: 10px; } }
      &__social {
        &-icons {
          justify-content: center; }
        &-icon {
          font-size: 44px;
          margin-right: 20px; } }
      &__top {
        padding: 0 30px;
        margin: 30px 0; }
      &__bottom {
        padding: 0 10px;
        width: 100%;
        .mvs-footer__personal-link {
          font-size: 16px; } } }
    .mvs-footer__item.--logo {
      display: flex; }
    .mvs-body__left-side {
      width: 0;
      .mvs-footer__logo-wrapper {
        display: none; } }
    .mvs-body__right-side {
      padding-left: 0; }
    .mvs-footer {
      padding-top: 100px; } }
  &.desktop,
  &.tablet {
    @media only screen and (max-width: 1440px) {
      .mvs-body__wrapper {
        .mvs-body__left-side {
          max-width: 25%;
          // max-width: 30%
          padding-right: 0; } }
      .mvs-footer {
        max-width: 880px; } }
    @media only screen and (max-width: 1024px) {
      .mvs-body__wrapper {
        .mvs-body__left-side {
          max-width: 25%;
          padding-right: 30px;
          @media only screen and (max-width: 1440px) {
            max-width: 30%;
            padding-right: 0; } } }
      .mvs-footer {
        max-width: 880px; } } } }
