@import "~@/sass/_variables";



























































































































































































































.mvs-header__social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-link {
    width: 42px;
    height: 42px;
    font-size: 42px;
    color: $light;
    opacity: .8;
    transition: .2s;
    cursor: pointer;
    &:hover {
      color: #fff; } }
  &-icons {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      justify-content: center;
      margin-bottom: 10px; } }
  &-icon {
    font-size: 70px;
    transition: .2s;
    margin-right: 50px;
    &:last-child {
      margin-right: 0; }
    @media only screen and (max-width: 1279px) {
      font-size: 70px; }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0; } }
    &:hover {
      color: #fff; } } }


.mvs-footer__social {
  &-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media only screen and (max-width: 767px) {
      justify-content: center;
      margin-bottom: 10px; } }
  &-icon {
    font-size: 65px;
    transition: .2s;
    margin-right: 45px;
    &:last-child {
      margin-right: 0; }
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      margin-right: 20px; }
    &:hover {
      color: #fff; } } }

.mvs-footer {
  height: 430px;
  color: $light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 880px;
  padding: 50px 0;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    height: 4px;
    background: #fff;
    width: 100%; }
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
    padding: 20px 0; }
  .mvs-wrap {
    width: 100%;
    @media only screen and (max-width: 767px) {
      max-width: 100%; } }
  &__top {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 60px;
    @media only screen and (max-width: 767px) {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0 22px;
      margin-bottom: 10px; } }
  &__bottom {
    text-align: center;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 0 22px; } }
  &__item {
    &.--logo {
      display: none; }
    &.--social {
      max-width: 40%;
      flex-grow: 1; }
    &.--links {
      flex-grow: 1;
      width: 65%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      .mvs-footer__link {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-right: 70px;
        &:last-child {
          justify-content: flex-start;
          margin-right: unset;
          margin-left: 50px; } } } }
  &__logo {
    display: block;
    width: 230px;
    &-wrapper {
      position: absolute;
      bottom: 245px;
      margin-right: 15px; }
    @media only screen and (max-width: 1279px) {
      width: 250px; }
    @media only screen and (max-width: 767px) {
      flex-grow: 1;
      width: 100%;
      img {
        max-width: 50%; } }
    img {
      width: 100%; } }
  &__links {
    display: flex;
    align-items: center; }
  &__link {
    text-transform: uppercase;
    font-size: 25px;
    transition: .2s;
    @media only screen and (max-width: 1279px) {
      margin-right: 50px;
      font-size: 28px; }
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 700;
      margin-right: 35px; }
    &:hover {
      color: #fff; }
    &:last-child {
      margin-right: 0; } }
  &__personal {
    margin-bottom: 40px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px; }
    &-link {
      color: #979797;
      line-height: 1.2;
      border-bottom: 1px solid #979797;
      font-size: 18px;
      font-weight: 300;
      &:hover {
        border: none; } } }
  &__copyright {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 300;
    color: #5c5c5c;
    @media only screen and (max-width: 767px) {
      font-size: 14px; } }
  &__requisites {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 300;
    color: #5c5c5c;
    @media only screen and (max-width: 767px) {
      font-size: 12px; } } }
